import Nav from "components/nav/Nav";
import CoreMenuNav from "components/nav/CoreMenuNav";
import Footer from "components/footer/Footer";
import CoreMenuFooter from "components/footer/CoreMenuFooter";
import Support from "./Support";
import { ReactNode, useEffect, useState } from "react";
import Head from "next/head";
// Login State
import {
    loginState,
    isFGDUserState,
    isFGDAdminState,
    initViewState,
    currentUserState,
    bypassSplashState,
    pageLoadedState,
    isCoreMenuState,
} from "state/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { useRouter } from "next/router";
// import { redirect } from "next/dist/next-server/server/api-utils";
import {
    fetchData,
    makeCookie,
    getCookie,
    clearCookie,
} from "helpers/functions";
import SplashPage from "pages/splashpage";
import LoadingScreen from "components/global/LoadingScreen";
import { initGA, logPageView } from "helpers/ga";

const login_url = process.env.NEXT_PUBLIC_LOGIN_URL;
const splash_mode_env = process.env.NEXT_PUBLIC_SPLASH_MODE;
const splashMode = splash_mode_env === "true";
const tagGTM = process.env.NEXT_PUBLIC_GTM_TAG;
const tagGA = process.env.NEXT_PUBLIC_GA_TAG;
const publicEnv = process.env.NEXT_PUBLIC_ENV;
const publicURL = process.env.NEXT_PUBLIC_FE_URL;

type Props = {
    children: ReactNode;
    footer?: boolean;
    navbar?: boolean;
    support?: boolean;
    title?: string;
};

// So typescript doesn't freak out in our GA code later on.
declare const window: any;

const Layout = ({ title, navbar = true, footer = true, support = true, children }: Props) => {
    const [currentURL, setCurrentURL] = useState("#");
    const [loginAttempted, setLoginAttempted] = useState(false);
    const [isLoggedin, setIsLoggedin] = useRecoilState(loginState);
    const [bypassSplash, setBypassSplash] = useRecoilState(bypassSplashState);
    const [pageLoaded, setPageLoaded] = useRecoilState(pageLoadedState);
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
    const [isFGDUser, setFGDUser] = useRecoilState(isFGDUserState);
    const [isFGDAdmin, setFGDAdmin] = useRecoilState(isFGDAdminState);
    let userInfo = { ...currentUser };

    // Login Logic

    const router = useRouter();

    const checkLogin = async () => {
        // console.log("checkLogin has been run");
        setCurrentURL(window.location.pathname);
        let track = userInfo && userInfo.roles !== undefined && userInfo.roles.length > 0 && (userInfo.roles[0] === 'superadmin' || userInfo.roles[0] === 'admin' || userInfo.roles[0] === 'fgd-admin' || userInfo.roles[0] === 'fg-admin') ? false : true;
        if (!loginAttempted && !isLoggedin) {
            try {
                const getUser = await fetchData("currentUser");
                if (getUser.error) {
                    if(router.pathname !== '/design/login' && !router.pathname.includes('/reset-password') && !router.pathname.includes('design/request')) {
                        makeCookie("initialUrl", window.location.pathname, 0.005);
                    }
                    setIsLoggedin(false);
                    setLoginAttempted(true);
                    if (router.pathname !== ('/design') && router.pathname !== ('/design/login') && !router.pathname.includes('/design/request') && !router.pathname.includes('/guidelines/') && !router.pathname.includes('/downloads/') && !router.pathname.includes('/getting-started') && !router.pathname.includes('/reset-password') && router.pathname !== ('/gallery')) {
                        router.push(
                            `${login_url}?return=${window.location.pathname}`
                        );
                    } else if (router.pathname !== 'design/login' && !router.pathname.includes('/reset-password') && !router.pathname.includes('design/request')) {
                        let publicUrl: string = publicURL ? publicURL : 'https://frontend.kmp.test';
                        if (publicEnv === 'Development') {
                            publicUrl = 'https://frontenddev.kmpadmin81.cliquedomains.com/';
                        } else if (publicEnv === 'Staging') {
                            publicUrl = 'https://staging.me.mcd.com';
                        } else if (publicEnv === 'Production') {
                            publicUrl = 'https://me.mcd.com';
                        }
                        setTimeout(() => {
                            router.push(`${publicUrl}/design/login`);
                        }, 100);
                    }
                } else if (!getUser.error) {
                    // console.log("getUser", getUser);
                    setIsLoggedin(true);
                    setCurrentUser(getUser.res.data);
                    if (getUser.res.data.roles && getUser.res.data.roles.length > 0 && (getUser.res.data.roles[0] === 'fg-user')) {
                        setFGDUser(true);
                    }
                    if (getUser.res.data.roles && getUser.res.data.roles.length > 0 && getUser.res.data.roles[0] === 'fg-admin' && getUser.res.data.roles[0] === 'fgd-admin') {
                        setFGDAdmin(true);
                    }
                    // if(getUser && getUser.res.data.id !== null && getUser.res.data.id !== undefined) {
                    //   if(window.dataLayer && window.dataLayer !== undefined) {
                    //     window.dataLayer.push({"user_id" : getUser.res.data.id});
                    //   }
                    // }
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const showPage = () => {
        if (
            // Pages where we'll control the pageLoaded global state on the view
            window.location.pathname === "/" ||
            window.location.pathname === "/homepage-preview" ||
            window.location.pathname.includes("/articles/") ||
            window.location.pathname.includes("/topics/") ||
            window.location.pathname.includes("/happy-meal/") ||
            window.location.pathname.includes("/case-study/") ||
            window.location.pathname.includes("/profile") ||
            window.location.pathname.includes("/faq") ||
            window.location.pathname.includes('/guidelines')
        ) {
            // Backup timeout just in case it's taking a REALLY long time to load.
            setTimeout(() => {
                setPageLoaded(true);
            }, 2000);
        } else {
            // Short timer to give just a little polish to the initial view
            setTimeout(() => {
                setPageLoaded(true);
            }, 125);
        }
    };

    // Logic for white loading screen
    useEffect(() => {
        // console.log("Layout Loaded");
        router.events.on("routeChangeStart", () => {
            setPageLoaded(false);
            // console.log("debug rout change started");
        });
        router.events.on("routeChangeComplete", () => {
            showPage();
            // console.log("debug route change complete");
        });
        router.events.on("routeChangeError", () => {
            setPageLoaded(true);
            // console.log("debug routError");
        });

        return () => {
            // console.log("route unmounting");
            router.events.off("routeChangeStart", () => {
                setPageLoaded(false);
            });
            router.events.off("routeChangeComplete", () => {
                showPage();
                // console.log("debug route change complete");
            });
            router.events.off("routeChangeError", () => {
                setPageLoaded(true);
                // console.log("debug routError");
            });
        };
    }, []);

    //Add correct page title to data layer
    useEffect(() => {
        const handleRouteChange = () => {
            setTimeout(() => {
                const pageLocation = window.location.pathname.split('/');
                const pageSlug = pageLocation.length > 0 ? pageLocation[pageLocation.length - 1] : 'Homepage';

                const makeTitle = (slug: any) => {
                    var words = slug.split('-');

                    for (var i = 0; i < words.length; i++) {
                        var word = words[i];
                        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
                    }

                    return words.join(' ');
                }

                let pageTitle = title !== undefined ? title : makeTitle(pageSlug);
            }, 1000);
        };

        router.events.on("routeChangeComplete", handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.asPath]);

    // const GTM_TAG = 'UA-210522132-1';
    // useEffect(() => {
    //     if (GTM_TAG) {
    //         // init GA if not loaded
    //         if (!window.GA_INITIALIZED) {
    //             initGA();
    //             window.GA_INITIALIZED = true;
    //             console.log("ga setup for analytics");
    //         }
    //         // Log the page view
    //         logPageView();
    //         console.log("ga logged page view");
    //     }
    // }, []);

    useEffect(() => {
        const splashCookie = getCookie("bypassSplash") === "true";
        setBypassSplash(splashCookie);
        if (!isLoggedin) {
            checkLogin();
        } else if (splashMode && !bypassSplash) {
            if (window.location.pathname === "/homepage") {
                setBypassSplash(true);
                makeCookie("bypassSplash", "true", 1);
                router.push(`/`);
            } else {
                console.log("pathname mismatch");
            }
        }
        // if (!splashMode || bypassSplash) {
        //     checkLogin();
        // }

        // Logic to redirect user if they're sent from Login
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sentFromLogin = urlParams.get("fromLogin");
        const initialUrl = getCookie("initialUrl");
        console.log('router pathname', router.pathname);
        console.log('window pathname', window.location.pathname);
        console.log('initialUrl', initialUrl);
        if (isLoggedin && initialUrl) {
            if(window.location.pathname === initialUrl) {
                clearCookie("initialUrl");
            }
            if(router.pathname === '/design' || router.pathname === '/' || sentFromLogin === "1") {
                router.push(initialUrl);
            }
        }
    }, [isLoggedin, bypassSplash]);

    if (router && (router.pathname.includes('design/login') || router.pathname.includes('/reset-password') || router.pathname.includes('/design/request'))) {
        return (
            <FGDLoginView
                title={title}
                children={children}
            />
        );
    } else if (!isLoggedin && loginAttempted) {
        return <LoginRedirect currentURL={currentURL} />;
    } else if (isLoggedin) {
        if (splashMode && !bypassSplash) {
            return <SplashPage />;
        } else if (
            splashMode &&
            bypassSplash &&
            window.location.pathname === "/homepage"
        ) {
            return <BypassRedirect />;
        } else {
            return (
                <MainView
                    pageLoaded={pageLoaded}
                    title={title}
                    navbar={navbar}
                    footer={footer}
                    support={support}
                    children={children}
                />
            );
        }
    }
    // Default to a blank page
    return <EmptyPage />;
};

export default Layout;

const FGDLoginView = ({ title, children }: any) => {

    return (
        <>
            <LoadingScreen loaded={true} />
            <Head>
                <title>{title} - Feel-Good Design Hub</title>
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ffbc0d" />
				<meta name="msapplication-TileColor" content="#ffbc0d" />
				<meta name="theme-color" content="#ffffff" />
            </Head>
            {children}
        </>
    )
}

const MainView = ({ pageLoaded, title, navbar, support, children, footer }: any) => {
    const pageLocation = window.location.pathname.trim().split('/');
    const pageSlug = pageLocation.length > 0 ? pageLocation[pageLocation.length - 1] : 'Homepage';
    const [isFGDUser, setFGDUser] = useRecoilState(isFGDUserState);

    const makeTitle = (slug: any) => {
        var words = slug.split('-');

        for (var i = 0; i < words.length; i++) {
            var word = words[i];
            words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }

        return words.join(' ');
    }

    let pageTitle = title !== undefined ? title : makeTitle(pageSlug);

    useEffect(() => {
        if (pageTitle !== 'Homepage') {
            setTimeout(() => {
                document.getElementsByTagName('body')[0].classList.remove('no-scroll');
            }, 500);
        }
    });

    const [homepageInit, setHomepageInit] = useRecoilState(initViewState);
    const initHomepageState = useRecoilValue(initViewState);
    const router = useRouter();

    function gaPageView() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'virtualPageview',
            'pageUrl': window.location.href,
            'pageTitle': document.title
        });
    }

    useEffect(() => {
        // console.log('router', router);
        if (pageTitle === 'Homepage') {
            if (initHomepageState) {
                console.log('initial homepage view');
                setHomepageInit(false);
            } else {
                console.log('return to homepage view');
                gaPageView();
            }
        } else {
            gaPageView();
        }
    }, [router.asPath]);


    // Check if FGD Use Has Access
    const [isFGDPage, setIsFGDPage] = useState<boolean>(false);
    const [fgdPage, setFGDPage] = useState<any>(null);
    const fgdPaths: any = ['/design', '/getting-started', '/search/design/[query]', '/guidelines/[...slug]', '/downloads/[...slug]', '/gallery', '/webcast-recordings/[slug]'];
    useEffect(() => {
        const pathname: any = router.pathname;
        // console.log('pathname', pathname);
        if (isFGDUser) {
            if (fgdPaths.indexOf(pathname) === -1) {
                setFGDPage(
                    <>
                        <main className="page errorPage">
                            <div className="wrapper">
                                <h1>404</h1>
                                <p>Sorry, the page you requested could not be found.</p>
                                <a className="btn narrow" href="/design">Back to Feel Good Design Home</a>
                            </div>
                        </main>
                    </>
                );
            } else {
                setFGDPage(null);
            }

            // Redirect ME homepage to FGD homepage
            let publicUrl: string = 'https://public.mcd.test';
            if (publicEnv === 'Development') {
                publicUrl = 'https://frontenddev.kmpadmin81.cliquedomains.com/';
            } else if (publicEnv === 'Staging') {
                publicUrl = 'https://staging.me.mcd.com';
            } else if (publicEnv === 'Production') {
                publicUrl = 'https://me.mcd.com';
            }
            if (pathname === '/') {
                router.push(`${publicUrl}/design`);
            }
        } else if (fgdPage !== null) {
            setFGDPage(null);
        }
    }, [router, isFGDUser]);

    // Check for Core Menu pages
    const [isCoreMenu, setIsCoreMenu] = useRecoilState(isCoreMenuState);
    const checkCoreMenu = () => {
        if(router.pathname === '/globalcoremenu' || router.pathname == '/governance-and-approval' || router.pathname == '/operations-testing-protocols' || router.pathname == '/protocols-for-managing-change' || router.pathname === '/change-plan' || router.pathname === '/change-plan/results' || router.pathname === '/change-plan/[slug]' || router.pathname === '/contact' || router.pathname === '/products' || router.pathname === '/products/[slug]' || router.pathname === '/products/[...slug]') {
            setIsCoreMenu(true);
        }
    }

    useEffect(() => {
        checkCoreMenu();
    }, [router]);

    return (
        <>
            <LoadingScreen loaded={pageLoaded} />
            <Head>
                {pageTitle && <title>{pageTitle}{isFGDUser ? ' - Feel-Good Design Hub' : ' - Marketing Exchange'}</title>}
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="/safari-pinned-tab.svg"
                    color="#db0007"
                />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="theme-color" content="#ffbc0d" />
            </Head>
            {/* <div className={`layoutWrapper ${pageLoad ? "show" : "hide"}`}> */}
            {navbar && !isCoreMenu && <Nav />}
            {isCoreMenu && <CoreMenuNav />}
            {support && <Support pageTitle={pageTitle} />}
            {fgdPage ? fgdPage : children}
            {footer && !isCoreMenu && <Footer />}
            {isCoreMenu && <CoreMenuFooter />}
            {/* </div> */}
        </>
    );
};

const LoginRedirect = ({ currentURL }: { currentURL: string }) => {
    return (
        <div>
            <br />
            <div className="wrapper">
                You are not logged in. Please{" "}
                <a
                    style={{ color: "red" }}
                    href={`${login_url}?return=${currentURL}`}>
                    click here
                </a>{" "}
                if you are not redirected in 3 seconds...
                {/* <br />
                    (20 seconds to slow down for dev testing, it will be almost
                    instant in live) */}
            </div>
        </div>
    );
};

const BypassRedirect = () => {
    return (
        <div>
            <br />
            <div className="wrapper">
                You are not logged in. Please{" "}
                <a style={{ color: "red" }} href={`/`}>
                    click here
                </a>{" "}
                if you are not redirected in 3 seconds...
            </div>
        </div>
    );
};

const EmptyPage = () => {
    return <div />;
};
